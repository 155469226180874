import { createSlice } from "@reduxjs/toolkit";

const archiveModeSlice = createSlice({
    name: "archiveMode",
    initialState: {
        archiveMode: false,
    },
    reducers: {
        toggleArchiveMode: (state) => {
            state.archiveMode = !state.archiveMode;
        }
    }
});


export const { toggleArchiveMode: toggleArchiveModeActionCreator } = archiveModeSlice.actions;

export default archiveModeSlice.reducer;