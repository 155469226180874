import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import VideoAnimation from "./components/Antimation/VideoAnimation";
import Display from "./components/Display/Display";
import Header from "./components/Header/Header";
import ModalImage from "./components/ModalImage/ModalImage";
import SideMenu from "./components/SideMenu/SideMenu";


const AppContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;

  @media (max-width: 768px) {
    flex-direction: column;
    height: 100vh;
    height: ${(props) => props.device === "iOS" ? "-moz-available" : "100vh"};
    height: ${(props) => props.device === "iOS" ? "-webkit-fill-available" : "100vh"};
    height: ${(props) => props.device === "iOS" ? "fill-available" : "100vh"};
    min-height: ${(props) => props.device === "iOS" ? "-webkit-fill-available" : "none"};
  }
`;

function App() {
  const { project } = useSelector((state) => state.project);
  const { showingProject } = useSelector((state) => state.project);
  const { projects } = useSelector((state) => state.allProjects);
  const { archiveMode } = useSelector((state) => state.archiveMode);

  const [mobileOS, setMobileOS] = useState(null);
  const [textShown, setTextShown] = useState(false);
  const [height, setHeight] = useState(null);
  const [projectsToShow, setProjectsToShow] = useState(projects)
  const ref = useRef(null);

  function getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
          return "Windows Phone";
      }
      if (/android/i.test(userAgent)) {
          return "Android";
      }
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
      }
      return "unknown";
  }

  useEffect(()=>{
    setMobileOS(getMobileOperatingSystem())
    setHeight(ref.current.offsetHeight);
  }, [height])

  useEffect(()=>{
    if (textShown) {
      console.log('CODE DEVELOPED BY ALEJANDRO MACHIN')
    }
      setTextShown(true)
  }, [textShown])

  useEffect(() => {
    if (archiveMode){
      const sortedProjects = [...projects].sort(function (a, b) {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      });
      setProjectsToShow(sortedProjects)
    }else{
      setProjectsToShow(projects)
    }
  }, [archiveMode, projects])
  return (
    <>
      <AppContainer ref={ref} className="APP" device={mobileOS}>
        <ModalImage/>
        {<VideoAnimation/>}
        <Header project={project} showingProject={showingProject} />
        <Display project={project} height={height} />
        <SideMenu   show={projectsToShow.length}
                infiniteLoop 
                projects={projectsToShow}/>
      </AppContainer>
    </>
  );
}

export default App;
