import { useDispatch } from "react-redux";
import styled from "styled-components";
import { setImageToShowActionCreator } from "../../redux/imageToShow";
import Iframe from "./Iframe/Iframe";

const DisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  gap: 3px;

    & img {
      height: 100%;
      width: auto;  
  }
  .firstImage {
    padding-top: 150px;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    gap: 10px;
    padding: 10px;
    height: ${(props) => props.height}px;
    
    .firstImage {
      padding-top: 0;
    }
  }
`;

const Display = ({ project, height }) => {
  
  const heightChild = height - 400;

  const dispatch = useDispatch();

  const openModal = (imageName) => {
    dispatch(setImageToShowActionCreator(imageName));
  };

  return (
    <DisplayContainer className="DISPLAY" height={heightChild}>
      {project.isVideo ? (
        <Iframe/>
      ) : (
        project.images.length > 0 && (
          project.images.map((image, index) =>
             <img src={`/images/${image}`} alt="project" onClick={()=>openModal(image)} className={index === 0 ? "firstImage" : ""}/>
          )
        )
      )}
    </DisplayContainer>
  );
};

export default Display;
