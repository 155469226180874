import { projectCounterByTag } from "../utils/projectCounterByTag";

export const fakeProjects = [
  {
    id: 1,
    title: "CASA BATLLÓ",
    subtitle: "THIS IS NOT A HOUSE",
    isVideo: true,
    description: "Directed by AITOR BIGAS / Produced by MAÑANA / Agency SOON IN TOKYO / DOP PEPE GAY / Edit AITOR BIGAS / Archive Research & Design GEMMA PENYA / Music & Sound BIEL BLANCAFOR / Color JULIA ROSSETTI",
    images: [],
    tags: ["DIRECTION"],
    date: "2021",
    url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/casa_batllo%CC%81_this_is_not_a_house.mp4?alt=media&token=f9f89210-206b-459a-ac33-43dab7c495f0",
    edit: false,
    still: "casa_batllo_this_is_not_a_house.00_00_00_00.Imagen-fija001.webp",
  },
  {
    id: 2,
    title: "THE IDEA OF THE CITY",
    isVideo: true,
    subtitle: "URBIDERMIS SANTA&COLE",
    description: "Directed by AITOR BIGAS / Creative direction QUERIDA / Production MAÑANA / Script VINCENZO ANGLIERI / DOP CARLES GALÍ / Edit AITOR BIGAS / Visual research GEMMA PENYA / Music & sound STUDIO EL / Color JULIA ROSSETTI",
    images: ["project2.png"],
    tags: ["DIRECTION"],
    date: "2022",
    url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/the_idea_of_the_city_%E2%80%93_urbidermis_santa%26cole.mp4?alt=media&token=7f816965-99ec-4558-b8fe-0d8b41e8cbac",
    edit: false,
    still: "the_idea_of_the_city_–_urbidermis_santa&cole.00_02_54_09.Imagen-fija002.webp"
  },
  {
    id: 3,
    title: "IMPERIA",
    description: "Directed by AITOR BIGAS / Produced by WHITE HORSE / Edit AITOR BIGAS / Starring HARLEY MARTINEZ / Text GEMMA PENYA FERRER / Music & Sound design STUDIO EL / Color LLUIS VELAMAZÁN / Graphics & postproduction FOLCH STUDIO",
    isVideo: true,
    subtitle: " ",
    images: ["project3.png"],
    tags: ["DIRECTION"],
    date: "2023",
    url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/imperia_%E2%80%93_speranza_di_progresso.mp4?alt=media&token=22ffdbbc-e0c3-480d-819c-0e7ad5af4287",
    vertical: false,
    edit: false,
    still: "imperia_–_speranza_di_progresso.00_01_38_02.Imagen-fija001.webp"
  },
  {
    id: 4,
    title: "UNIDADES DE PRESIÓN",
    subtitle: " ",
    isVideo: true,
    description: "Directed by AITOR BIGAS / Sculptures by GONZALO GUZMÁN / Produced by MAÑANA / Dop CARLES GALÍ / Edit AITOR BIGAS / Music & sound EDUARD BUJALANCE / Color JULIA ROSSETTI",
    images: ["project4.png", "project3.png", "project2.png"],
    tags: ["DIRECTION"],
    date: "2024",
    url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/unidades_de_presio%CC%81n.mp4?alt=media&token=097ab104-69b3-4498-b37b-69f7c3b215af",
    edit: false,
    still: "unidades.webp"
  },
  {
    id: 5,
    title: "CENTRO MADRID",
    description: "Directed by AITOR BIGAS / Produced by MAÑANA / Creative direction QUERIDA / Foto RAFA CASTELLS / Edit AITOR BIGAS / Music & sound EDUARD BUJALANCE / Color JULIA ROSSETTI",
    subtitle: " ",
    images: [],
    tags: ["DIRECTION"],
    date: "2021",
    url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/CENTRO_MADRID.mp4?alt=media&token=441f6d1a-0640-4cb9-99d2-39434e1ae001",
    edit: false,
    still: "CENTRO_MADRID.00_00_33_14.Imagen-fija001.webp",
    isVideo: true,
  },
  {
    id: 6,
    title: "ACNE STUDIOS",
    subtitle: "ARQUITECTURA-G",
    description: "Directed by MANSON / Produced by BLUR / Dop by PEPE GAY / Edit AITOR BIGAS / Music & sound STUDIO EL / Color MARC MORATÓ",
    images: ["project6.tiff"],
    tags: ["EDIT"],
    date: "2024",
    url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/acne_studios_for_arquitectura-g.mp4?alt=media&token=e9efbbe0-6216-4bde-90fa-8f60e2e901e1",
    edit: true,
    isVideo: true,
    still: "acne_studios_for_arquitectura-g.00_00_15_00.Imagen-fija001.webp",
  },
  {
    id: 7,
    title: "BAD BUNNY",
    subtitle: "TITI ME PREGUNTÓ",
    description: "Directed by STILLZ / Produced by WE OWN THE CITY / Dop RUSS FRASER / Edit AITOR BIGAS / Color COMPANY 3",
    images: [],
    tags: ["EDIT"],
    date: "2021",
    url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/bad_bunny_%E2%80%93_titi_me_preguntO%CC%81-.mp4?alt=media&token=41462220-8f0d-4066-b739-16cf5d35ea6c",
    edit: true,
    isVideo: true,
    still: "bad_bunny_–_titi_me_pregunto-.00_00_10_11.Imagen-fija001.webp"
  },
  {
    id: 8,
    title: "ZARA",
    subtitle: "FREE TO BE WILD",
    description: "Directed by ALBERT MOYA / Produced by CAP / Dop JOERG WIDMER / Edit AITOR BIGAS / Music & sound MORITZ STAUB",
    images: [],
    tags: ["EDIT"],
    date: "2021",
    url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/zara_%E2%80%93_free_to_be_wild-.mp4?alt=media&token=4ba6a897-036f-4934-8e4e-21becd342f4b",
    edit: true,
    isVideo: true,
    still: "zara_–_free_to_be_wild-.00_00_10_16.Imagen-fija001.webp"
  },
  {
    id: 9,
    title: "ROSALÍA & ROMEO SANTOS",
    subtitle: "EL PAÑUELO",
    description: "Directed by ROGER GUARDIA / Produced by CANADA & PARK PICTURES / Dop DANIEL F. ABELLÓ / Edit AITOR BIGAS / Color JASON WALLIS",
    images: [],
    tags: ["EDIT"],
    date: "2021",
    url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/rosalia_%26_romeo_santos_%E2%80%93_el_paN%CC%83uelo.mp4?alt=media&token=7d9449c6-c32d-43b7-9c99-4fe48688b7ae",
    edit: true,
    isVideo: true,
    still: "rosalia_&_romeo_santos_–_el_panuelo.00_01_36_29.Imagen_fija001.webp"
  },
  {
    id: 10,
    title: "CALVIN KLEIN",
    subtitle: "OMAR AYUSO #MyCalvins",
    description: "Directed by GORKA POSTIGO / Produced by CAP / Dop URI BARCELONA / Edit AITOR BIGAS / Sound PAM PAM / Color METROPOLITANA",
    images: [],
    tags: ["EDIT"],
    date: "2021",
    url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/calvin_klein_%E2%80%93_omar_ayuso_%23mycalvins.mp4?alt=media&token=9197bafa-6bc9-4dfe-8820-7957112b2000",
    edit: true,
    isVideo: true,
    still: "omar.webp"
  },
  {
    id: 11,
    title: "CALVIN KLEIN",
    subtitle: "ARCA #MyCalvins",
    description: "Directed by GORKA POSTIGO / Produced by CAP/ Dop URI BARCELONA / Edit AITOR BIGAS / Sound PAM PAM / Color METROPOLITANA",
    images: [],
    tags: ["EDIT"],
    date: "2021",
    url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/calvin_klein_%E2%80%93_arca_%23mycalvins.mp4?alt=media&token=b32329f6-124f-47ce-9b4c-7f9739b0fe6b",
    edit: true,
    isVideo: true,
    still: "arca.webp"
  },
    {
    id: 13,
    title: "PIRELLI",
    subtitle: "150th Anniversary",
    description: "Directed by MANSON / Produced by CANADA / DOP by MATIAS PENACHINO / Edit AITOR BIGAS / Music & sound STUDIO EL / Color DANIEL DE VUE",
    images: [],
    tags: ["EDIT"],
    date: "2021",
    url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/pirelli_%E2%80%93_150th_aniversary.mp4?alt=media&token=afde4f7d-9a28-45c1-a2d5-bdf49aa8b427",
    edit: true,
    isVideo: true,
    still: "pirelli_–_150th_aniversary.00_00_09_10.Imagen-fija001.webp"
  },
    {
    id: 14,
    title: "ARCA",
    subtitle: "TIME",
    description: "Directed by MANSON / Produced by CANADA / Creative direction ARCA, CARLOS SAEZ, MANSON / Dop GONZALO HERGUETA / Edit AITOR BIGAS / Color JULIA ROSSETTI",
    images: [],
    tags: ["EDIT"],
    date: "2021",
    url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/arca_%E2%80%93_time.mp4?alt=media&token=74b74726-a448-4bfc-a3e9-aa58a200b557",
    edit: true,
    isVideo: true,
    still: "project14.webp"
  },
    {
    id: 15,
    title: "ZARA ORIGINS",
    subtitle: "HIER IST KALT WIE IM WINTER",
    description: "Directed by DIRECTBYNT / Produced by CANADA / Dop RYAN MARIE HELFANT / Edit AITOR BIGAS / Music BEN CLOCK / Sound IXYXI / Color MARC MORATÓ",
    images: [],
    tags: ["EDIT"],
    date: "2021",
    url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/zara_origins_%E2%80%94_hier_ist_kalt_wie_im_winter.mp4?alt=media&token=915af051-4955-4ab9-8674-7a0e21dce27f",
    edit: true,
    isVideo: true,
    still: "zara_origins_—_hier_ist_kalt_wie_im_winter.00_00_11_23.Imagen-fija001.webp"
  },
  {
    id: 16,
    title: "RYUICHI SAKAMOTO",
    subtitle: "SILENCE",
    description: "Directed by DIANA KUNST / Produced by BLUR / Creative direction PRSNT / Dop LIONEL CABRERA / Edit AITOR BIGAS / Color RUBEN GALABRA",
    images: [],
    tags: ["EDIT"],
    date: "2021",
    url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/ryuichi_sakamoto_%E2%80%93_silence%20.mp4?alt=media&token=892d624d-5e0d-47d7-9cfd-e9530e575c0e",
    edit: true,
    isVideo: true,
    still: "ryuichi_sakamoto_–_silence.00_00_05_22.Imagen-fija001.webp"
  },
  {
    id: 17,
    title: "ROLLING STONES",
    subtitle: "CRISS CROSS",
    description: "Directed by DIANA KUNST / Produced by OBJECT & ANIMAL / Dop URI BARCELONA / Edited btb with CARLOS FONT CLOS / Color MARC MORATÓ",
    images: [],
    tags: ["EDIT"],
    date: "2021",
    url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/the_rolling_stones_%E2%80%93_criss_cross.mp4?alt=media&token=9dbdc77e-6bc1-4d14-9d84-fbbf3d7951e6",
    edit: true,
    isVideo: true,
    still: "stones.webp"
  },
  {
    id: 18,
    title: "BAD GYAL",
    subtitle: "SIN CARNÉ",
    description: "Directed by MANSON / Produced by CANADA / Dop PEPE GAY / Edit AITOR BIGAS / Color MARC MORATÓ",
    images: [],
    tags: ["EDIT"],
    date: "2021",
    url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/bad_gyal_-_sin_carne%CC%81_(2022)_%23man%20(1080p).mp4?alt=media&token=1cae1e14-41b2-41aa-a8e6-5655bbf25f85",
    edit: true,
    isVideo: true,
    still: "badgyal.webp"
  },
  {
    id: 19,
    title: "BAD BUNNY",
    subtitle: "MOSCOW MULE",
    description: "Directed by STILLZ /  Produced by WE OWN THE CITY / Dop RUSS FRASER & PEPE GAY / Edit AITOR BIGAS / Color COMPANY 3",
    images: [],
    tags: ["EDIT"],
    date: "2021",
    url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/bad_bunny_%E2%80%93_moscow_mule%20.mp4?alt=media&token=13f12d82-1f43-468d-88ce-459b18ba9c85",
    edit: true,
    isVideo: true,
    still: "bad_bunny_–_moscow_mule-.00_03_06_03.tif-fija001.webp"
  },
  {
    id: 20,
    title: "VTOPIA",
    subtitle: "KAAN ARCHITECTEN",
    description: "Directed by JOANA COLOMAR / Dop NILO ZIMMERMAN / Edit AITOR BIGAS / Music ED MARAIS / Color JULIA ROSSETTI",
    images: [],
    tags: ["EDIT"],
    date: "2021",
    url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/vtopia_%E2%80%93_kaan_architecten.mp4?alt=media&token=6d944586-16a1-4745-900a-c7613cbadf58",
    edit: true,
    isVideo: true,
    still: "vtopia_–_kaan_architecten.00_02_12_10.Imagen-fija001.webp"
  },
    {
    id: 21,
    title: "ARON",
    subtitle: "NIEVE",
    description: "Directed by MANSON / Produced by CANADA / Dop PEPE GAY / Edit AITOR BIGAS / Color MARC MORATÓ",
    images: ["project21.tif"],
    tags: ["EDIT"],
    date: "2021",
    url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/aron_piper_-_nieve_(2021)_%23man%20(1080p).mp4?alt=media&token=d3022f4c-0f28-47d6-b669-fb03c55167d4",
    edit: true,
    isVideo: true,
    still: "project21.webp"
  },
  { 
    id: 22,
    title: "INSIGNIA",
    subtitle: " ",
    description: "Fotos AITOR BIGAS / Edited by CLANDESTINE BOOKS / Design MARC MIRÓ / Text GEMMA PENYA / Translation HEATHER CAMERON / Product photography PABLO DE PASTORS & INDRA ZABALA",
    images: ["insignia/insignia_001.webp", "insignia/insignia_003.webp", "insignia/insignia_004.webp", "insignia/insignia_010.webp", "insignia/insignia_011.webp", "insignia/insignia_012.webp", "insignia/insignia_013.webp", "insignia/insignia_014.webp", "insignia/insignia_017.webp", "insignia/insignia_020.webp", "insignia/insignia_021.webp", "insignia/insignia_024.webp"],
    tags: ["PAPER"],
    date: "2021",
    isVideo: false,
    still: "insignia/insignia_001.webp",
    vertical: true
  },
   { 
    id: 23,
    title: "TOUS",
    subtitle: "KEEP CURIOSITY ALIVE",
    description: "Directed by GORKA POSTIGO / Produced by CAP / Dop PEPE GAY /Edit AITOR BIGAS / Music PAMPAM / Color MARC MORATÓ / Set design HAKO",
    images: [],
    tags: ["EDIT"],
    date: "2021",
    isVideo: true,
    url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/TOUS_SANVALENTIN_MAIN_V9_16-9.mp4?alt=media&token=8a294c3a-a2a2-4dd9-8dc3-0ea9f81d9be7",
    still: "TOUS_SANVALENTIN_MAIN_V9_16-9.00_00_00_11.Imagen_fija001.webp",
    vertical: false,
  },
  {
  id: 24,
  title: "LOEWE",
  subtitle: "Spring Summer 2023 Shoes",
  description: "Directed by ALBERT MOYA / Produced by ART + COMMERCE / Dop JAKE GABBAY / Edit by AITOR BIGAS / Color FLORIAN MARTINY",
  images: [],
  tags: ["EDIT"],
  date: "2023",
  isVideo: true,
  url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/LOEWE_SHOES_CAPSULES_DC_V8_H264_6.mp4?alt=media&token=c26f1b98-625b-459c-8e4b-fda39d4f707c",
  still: "LOEWE_SHOES.webp",
  vertical: false
},
{
  id: 25,
  title: "LOUIS VUITTON",
  subtitle: "BY THE POOL",
  description: "Directed by ALBERT MOYA / Produced by ART + COMMERCE / Dop ??? / Edit by AITOR BIGAS / Music by AIR / Color FLORIAN MARTINY",
  images: [],
  tags: ["EDIT"],
  date: "2023",
  isVideo: true,
  url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/LV_POOL_MAIN_V13_DC_AdobeExpress.mp4?alt=media&token=e2371ec2-9f05-4594-a3b3-e03c96efad32",
  still: "LOUISV.webp",
  vertical: false
},
{
  id: 26,
  title: "BAD BUNNY",
  subtitle: "WHERE SHE GOES",
  description: "Directed by STILLZ / Produced by WE OWN THE CITY / Dop MARCELL REV / Edit AITOR BIGAS / Color COMPANY 3",
  images: [],
  tags: ["EDIT"],
  date: "2023",
  isVideo: true,
  url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/BB_WSG_FINAL_2K.mp4?alt=media&token=3feef8e3-457f-437c-8ded-e7d6524d1009",
  still: "BADBUNNY.webp",
  vertical: false
},
{
  id: 27,
  title: "LOEWE",
  subtitle: "THE ARTWORKS",
  description: "Directed by MASSIMILIANO BOMBA / Produced by DIVISION / Dop HAMPUS NORDENSON / Edit AITOR BIGAS / Music HIDDEN MOUNTAIN / Color DANIEL PALLUCA",
  images: [],
  tags: ["EDIT"],
  date: "2023",
  isVideo: true,
  url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/LOEWE_FOUNDATION_DC_MASTER.mp4?alt=media&token=5baaa7ab-c19c-486b-a27d-402d73b88c43",
  still: "LOEWE_FUNDATION.webp",
  vertical: false
},
{
  id: 28,
  title: "PACO RABANNE WITH CHARLIE R. HEATON",
  subtitle: "",
  description: "Directed by GORKA POSTIGO / Produced by FONDAMENTALE / Edit by AITOR BIGAS / Music by DAVID BOWIE",
  images: [],
  tags: ["EDIT"],
  date: "2023",  
  isVideo: true,
  url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/PACO_RABANNE_DC_MASTER_H264.mp4?alt=media&token=2d6b4cb2-ebb6-42d6-ad2e-b200bf722bcd",
  still: "PACO_RABANNE.webp",
  vertical: false
},
{
  id: 29,
  title: "ROSALÍA & RAUW ALEJANDRO",
  subtitle: "VAMPIROS",
  description: "Directed by STILLZ / Produced by CANADA / Dop URI BARCELONA / Edit AITOR BIGAS / Color COMPANY 3",
  images: [],
  tags: ["EDIT"],
  date: "2023",  
  isVideo: true,
  url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/RR_VAMP_310323_1920x1080.mp4?alt=media&token=8783ce59-fb66-49e7-9715-7021647c1f26",
  still: "ROSALIA.webp",
  vertical: false
},
{
  id: 30,
  title: "SEN SENRA",
  subtitle: "NO QUIERO SER UN CANTANTE",
  description: "Directed by TORSO / Produced by CAP / Creative Direction ANDREA LAZAROV / Edit AITOR BIGAS",
  images: [],
  tags: ['EDIT'],
  date: "2023",
  isVideo: true,
  url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/SENSENRA_TORSO_16-9_FHD_v2_H264.mp4?alt=media&token=7203feaa-ab54-45e3-aca0-0db5f6a58e0a",
  still: "SENSENRA.webp",
  vertical: false
},
{
  id: 31,
  title: "RAUW ALEJANDRO",
  subtitle: "QUE RICO CH**NGAMOS",
  description: "Directed by STILLZ / Produced by WE OWN THE CITY / Dop RUSS FRASER / Edit AITOR BIGAS / Color KAITLYN BATTISTELLI",
  images: [],
  tags: ['EDIT'],
  date: "2023",
  isVideo: true,
  url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/RAUW_TOKYO_MASTER_UNCENSORED.mp4?alt=media&token=19a57ff9-b153-42e6-b96f-15b8d623cc86",
  still: "RAUW.webp",
  vertical: false
},
// {
//   id: 32,
//   title: "MANGO",
//   subtitle: "FROM THE SEA, FOR THE SEA",
//   description: "Directed by AITOR BIGAS & GEMMA PENYA / Produced by HOLIDAYS / Dop HELENA J BARRERO / Edit AITOR BIGAS / Color LLUÍS VELAMAZÁN",
//   images: [],
//   tags: ["DIRECTION"],
//   date: "2023",
//   isVideo: true,
//   url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/MSF_MANGO_EDIT_V6%20(DC)_MASTER.mp4?alt=media&token=d218a290-7d72-43e3-b17a-cd1c82bbe261",
//   still: "MANGO.webp",
//   vertical: false
// },
{
  id: 33,
  title: "MIU MIU",
  subtitle: "LES EAUX À LA MODE",
  description: "Directed by GORKA POSTIGO / Produced by CADENCE / Edit & sound AITOR BIGAS / Color LLUÍS VELAMAZÁN",
  images: [],
  tags: ["EDIT"],
  date: "2023",
  isVideo: true,
  url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/MIU%20MIU_DC_COLOR_V1.mp4?alt=media&token=107a7b54-8c39-4e9c-acc4-b45716581868",
  still: "miu_miu.webp",
  vertical: false
},
{
  id: 34,
  title: "LOEWE",
  subtitle: "BOTANICAL RAINBOW",
  description: "Directed by ALBERT MOYA / Creative direction JONATHAN ANDERSON / Produced by ART + COMMERCE / Dop MARCELL REV / Edit by AITOR BIGAS / Color FLORIAN MARTINY",
  images: [],
  tags: ["EDIT"],
  date: "2023",
  isVideo: true,
  url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/MSF_MANGO_EDIT_V6%20(DC)_MASTER.mp4?alt=media&token=d218a290-7d72-43e3-b17a-cd1c82bbe261",
  still: "loewe_botanical.webp",
  vertical: false
},
{
  id: 35,
  title: "LOUIS VUITTON",
  subtitle: "CRUISE",
  description: "Directed by MASSIMILIANO BOMBA / Produced by DIVISION / Dop HAMPUS NORDENSON / Edit AITOR BIGAS / Music JSDS / Color JULIEN ALARY",
  images: [],
  tags: ["EDIT"],
  date: "2024",
  isVideo: true,
  url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/MSF_MANGO_EDIT_V6%20(DC)_MASTER.mp4?alt=media&token=d218a290-7d72-43e3-b17a-cd1c82bbe261",
  still: "louis_vuitton_i_cruise.webp",
  vertical: false
},
{
  id: 36,
  title: "BILLIE EILISH for GUCCI",
  subtitle: " ",
  description: "Directed by MARCELL REV / Produced by GE PROJECTS / Dop EDU GRAU / Edit AITOR BIGAS / Music BILLIE EILISH / Color DANTE GIANI",
  images: [],
  tags: ["EDIT"],
  date: "2024",
  isVideo: true,
  url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/MSF_MANGO_EDIT_V6%20(DC)_MASTER.mp4?alt=media&token=d218a290-7d72-43e3-b17a-cd1c82bbe261",
  still: "gucci_horsebit.webp",
  vertical: false
},
{
  id: 37,
  title: "BJORK & ROSALÍA",
  subtitle: "ORAL",
  description: "Directed by CARLOTA GUERRER / Produced by PRODCO / Dop ALVAR RIU / Edit AITOR BIGAS  / VFX MR FLOWERS / Color MARTI SOMOZ",
  images: [],
  tags: ["EDIT"],
  date: "2024",
  isVideo: true,
  url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/MSF_MANGO_EDIT_V6%20(DC)_MASTER.mp4?alt=media&token=d218a290-7d72-43e3-b17a-cd1c82bbe261",
  still: "bjork_rosalia.webp",
  vertical: false
},
{
  id: 38,
  title: "BAD BUNNY",
  subtitle: "MONACO",
  description: "Directed by STILLZ / Produced by WE OWN THE CITY / Dop CHRISTOPHER RIPLEY / Edit AITOR BIGAS / Color COMPANY 3 / Sound RAPHAEL AJUELOS",
  images: [],
  tags: ["EDIT"],
  date: "2024",
  isVideo: true,
  url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/MSF_MANGO_EDIT_V6%20(DC)_MASTER.mp4?alt=media&token=d218a290-7d72-43e3-b17a-cd1c82bbe261",
  still: "bad_bunny_monaco.webp",
  vertical: false
},
{
  id: 39,
  title: "BAD BUNNY",
  subtitle: "BATICANO",
  description: "Directed by STILLZ / Produced by WE OWN THE CITY / Dop MARCELL REV / Edit AITOR BIGAS / Color COMPANY 3 / Sound RAPHAEL AJUELOS",
  images: [],
  tags: ["EDIT"],
  date: "2024",
  isVideo: true,
  url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/MSF_MANGO_EDIT_V6%20(DC)_MASTER.mp4?alt=media&token=d218a290-7d72-43e3-b17a-cd1c82bbe261",
  still: "bad_bunny_baticano.webp",
  vertical: false
},
{
  id: 40,
  title: "ZARA",
  subtitle: "JOIN LIFE",
  description: "Directed by AITOR BIGAS / Produced by MAÑANA / Agency QUERIDA / Dop PEPE GAY / Edit AITOR BIGAS / Archive Research  GEMMA PENYA / Music & Sound BIEL BLANCAFORT / Color ALBERTO RODRIGUEZ",
  images: [],
  tags: ["DIRECTION"],
  date: "2024",
  isVideo: true,
  url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/MSF_MANGO_EDIT_V6%20(DC)_MASTER.mp4?alt=media&token=d218a290-7d72-43e3-b17a-cd1c82bbe261",
  still: "zara_join_life.webp",
  vertical: false
},
{
  id: 41,
  title: "LA BOTELLA CÓMO HÉROE",
  subtitle: "BRUTUS",
  description: "Directed by AITOR BIGAS / Produced by VIVIR RODANDO / Creative direction AITOR BIGAS & GEMMA PENYA / Copy LUCA DOBRY / Dop BORJA LLORENS / Edit AITOR BIGAS / Archive Research GEMMA PENYA / Music & Sound PEDRO VIAN / Color ALBERTO RODRIGUEZ",
  images: [],
  tags: ["DIRECTION"],
  date: "2024",
  isVideo: true,
  url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/BRUTUS_MASTER_FINAL.mp4?alt=media&token=7f1f140f-7a75-4d4c-b555-b24bb5004f8b",
  still: "brutus_master.webp",
  vertical: false
},
{
  id: 42,
  title: "DESPERTANÇA",
  subtitle: " ",
  description: "Fotos AITOR BIGAS & GEMMA PENYA / Edited by CONJUNTO VACÍO / Design ALBERT PÉREZ / Text SERGI ALGIZ / Product photography JOAN GAROLERA",
  images: ['despertanza/foto_22.webp', 'despertanza/foto_23.webp', 'despertanza/foto_24.webp', 'despertanza/foto_25.webp', 'despertanza/foto_26.webp', 'despertanza/foto_27.webp', 'despertanza/foto_28.webp', 'despertanza/foto_29.webp', 'despertanza/foto_30.webp', 'despertanza/foto_31.webp', 'despertanza/foto_32.webp', 'despertanza/foto_33.webp', 'despertanza/foto_34.webp', 'despertanza/foto_35.webp'],
  tags: ["PAPER"],
  date: "2024",
  isVideo: false,
  still: "despertanza/portada.webp",
  vertical: true
},


// {
//   id: 42,
//   title: "despertanza",
//   subtitle: " ",
//   description: "Fotos AITOR BIGAS & GEMMA PENYA / Edited by CONJUNTO VACÍO / Design ALBERT PÉREZ / Text SERGI ALGIZ / Product photography JOAN GAROLERA",
//   images: ['despertanza/foto_22.webp', 'despertanza/foto_23.webp', 'despertanza/foto_24.webp', 'despertanza/foto_25.webp', 'despertanza/foto_26.webp', 'despertanza/foto_27.webp', 'despertanza/foto_28.webp', 'despertanza/foto_29.webp', 'despertanza/foto_30.webp', 'despertanza/foto_31.webp', 'despertanza/foto_32.webp', 'despertanza/foto_33.webp', 'despertanza/foto_34.webp', 'despertanza/foto_35.webp'],
//   tags: ["PAPER"],
//   date: "2024",
//   isVideo: false,
//   still: "despertanza/portada.webp",
//   vertical: true
// },
{
  id: 43,
  title: "A$AP ROCKY",
  subtitle: "HIGHJACK",
  description: "Directed by THIBAUT GREVET / Produced by DIVISION / Dop JOAO DE BOTELHO / Edit by AITOR BIGAS / Postproduction 1080",
  images: [],
  tags: ["EDIT"],
  date: "2024",
  isVideo: true,
  url: "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/AR_HIGHJACK_MASTER_240821_DELIVERY_V2.mp4?alt=media&token=03c9fcbf-9990-401d-b8a0-cf1a5f2036a5",
  still: "assap_highjack.webp",
  vertical: false
}
].sort((a, b) => 0.5 - Math.random())

const filters = ["EDIT", "DIRECTION", "PAPER"];
export const filterTags = filters.map((tag) => ({ name: tag, total: projectCounterByTag(fakeProjects, tag) }));
