import { useDispatch } from "react-redux";
import styled from "styled-components";
import { setProjectActionCreator } from "../../../redux/projectSlice";

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  cursor: pointer;
  & h2,
  p {
    font-size: 10px;
    font-weight: 400;
  }
    @media (max-width: 768px) {
      width: 200px;
  }
`;


const ArchiveProject = ({ project }) => {
  const dispactch = useDispatch();

  const setProject = () => {
    dispactch(setProjectActionCreator(project));
  };

  return (
    <ProjectContainer className="archiveProject" onClick={setProject}>
      <h2>{project.title}</h2>
      <p>{project.subtitle}</p>
    </ProjectContainer>
  );
};

export default ArchiveProject;
