import Title from "./Title/Title";
import styled from "styled-components";
import Contact from "./Contact/Contact";
import Filters from "./Filters/Filters";
import { useDispatch, useSelector } from "react-redux";
import { cleanProjectActionCreator } from "../../redux/projectSlice";
import { filterTags } from "../../Data/Data";
import { toggleArchiveModeActionCreator } from "../../redux/archiveModeSlice";

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 150px;
  font-size: 12px;
  /* -webkit-box-shadow: 0px 5px 5px -5px rgb(0 0 0 / 60%);
  -moz-box-shadow: 0px 5px 5px -5px rgb(0 0 0 / 60%);
  box-shadow: 0px 5px 5px -5px rgb(0 0 0 / 60%); */
  gap: 25px;
  z-index: 1;
  background-color: #fff;
  opacity: 0.9;
  position: fixed;
  top: 0;
  left: 0;

  @media (max-width: 768px) {
    padding: 10px;
    position: inherit;
    height: 200px;
  }
`;
const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-top: 25px;

  @media (max-width: 768px) {
    gap: 20px;
  }
`;
const ProjectInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 25px 220px;
  text-align: center;

  & .description {
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    padding: 0;
    margin-top: 25px;
  }
`;

const Close = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 20;
   :hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &.hide {
    opacity: 0;
  }
  @media (max-width: 768px) {
    position: inherit;
  }
`;

const Header = ({ project, showingProject }) => {
  const {archiveMode} = useSelector((state) => state.archiveMode);
  const dispatch = useDispatch();
 
  const close = () => {
    dispatch(cleanProjectActionCreator());
  };
  const archiveModeOff = () => {
    dispatch(toggleArchiveModeActionCreator());
  }

  return (
    <HeaderContainer className="HEADER">
      {!showingProject ? (
        <>
          <HeaderContent>
            <Title />
            <Contact />
          </HeaderContent>
          <Close 
          className={archiveMode ? "" : "hide"} 
          disabled={archiveMode ? false : true} 
          onClick={archiveMode ? archiveModeOff : null}
          >
            (X) CLOSE
          </Close>
          <Filters filters={filterTags}/>
        </>
      ) : (
        <>
          <ProjectInfo>
            <p>{project.title}</p>
            <p className={project.subtitle === " " ? "transparent" : ""}>{project.subtitle === " " ? " - " : project.subtitle}</p>
            <p className="description">{project.description}</p>
          </ProjectInfo>
          <Close onClick={close}>(X) CLOSE</Close>
        </>
      )}
    </HeaderContainer>
  );
};

export default Header;
