
import { useCallback, useEffect } from "react";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ArchiveProject from "./Project/ArchiveProject";
import Project from "./Project/Project";

export const SliderContainer = styled.div`
  .service-contain {
    width: 18vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-x: auto;
    scrollbar-width: none;
    gap: ${(props) => (props.archive ? "10px" : "40px")};
    padding: ${(props) => (props.archive ? "20px" : "0")};
    padding-top: 150px;
    /* -webkit-box-shadow: 4px 0px 10px -5px rgba(0, 0, 0, 0.62);
    -moz-box-shadow: 4px 0px 10px -5px rgba(0, 0, 0, 0.62);
    box-shadow: 4px 0px 10px -5px rgba(0, 0, 0, 0.62); */

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .service-contain::-webkit-scrollbar {
    display: none;
  }

  .child {
    width: 100%;
    min-height: 100px;
    background-color: red;
  }

  .year{
    font-size: 10px;
    margin: 10px;
    margin-left: 15px;
    margin-top: 0;
  }

  @media (max-width: 768px) {
   
    .service-contain {
      flex-wrap: ${(props) => (props.archive ? "wrap" : "nowrap")};
      width: 100vw;
      height: 200px;
      flex-direction: ${(props) => (props.archive ? "column" : "row")}; 
      padding: 10px;
      padding-top: 5px;
      gap: ${(props) => (props.archive ? "7px" : "50px")};
      overflow-y: ${(props) => (props.archive ? "auto" : "hidden")};
    }
    .child {
      width: 100px;
      min-height: 100px;
      background-color: red;
    }
  }
`;

const SideMenu = ({ projects }) => {

  const { archiveMode } = useSelector((state) => state.archiveMode);
  const [projectsToShow, setProjectsToShow] = useState(null)
  const isMobile = window.innerWidth <= 768;

  const scrollleft = useCallback((e) => {
    const slider = ref.current;
    if (slider) {
      if (isMobile) {
        slider.scrollLeft = 0;
      } else {
        slider.scrollTop = 0;
      }
    }
  }, [isMobile]);

  useEffect(() => {
    setProjectsToShow(projects);
    scrollleft()
  }, [projects, scrollleft, archiveMode])


  const ref = useRef(null);
  let mouseDown = false;
  let startX, scrollLeft;
  let startY, scrollTop;

  let startDragging = (e) => {
    const slider = ref.current;
    if (slider) {
      mouseDown = true;
      if (isMobile) {
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      }else{
        startY = e.pageY - slider.offsetTop;
        scrollTop = slider.scrollTop;
      }
    }
  };

  let stopDragging = function (event) {
    mouseDown = false;
  };

  const mouseMove = (e) => {
    const slider = ref.current;

    e.preventDefault();
    if (!mouseDown) {
      return;
    }
    if (slider) {
      if(isMobile){
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
      }else{
        const y = e.pageY - slider.offsetTop;
        const scroll = y - startY;
        slider.scrollTop = scrollTop - scroll;
      }
    }
  };

  const onScroll = (ev) => {
    if (
      (!isMobile && (Math.ceil(ref.current.scrollTop) + ref.current.clientHeight) >= (ref.current.scrollHeight - 100)) || 
      (!isMobile && (ref.current.scrollTop + ref.current.clientHeight) >= (ref.current.scrollHeight - 100)) ||
      (isMobile && (ref.current.scrollLeft + ref.current.clientWidth) >= (ref.current.scrollWidth - 100))
    ) {
      setProjectsToShow([...projectsToShow, ...projects])
    }
  };

  return (
    <>
      <SliderContainer className="SIDEMENU" archive={archiveMode}>
        <div
          className={archiveMode ? "service-contain archive" : "service-contain"}
          ref={ref}
          onMouseDown={startDragging}
          onMouseLeave={stopDragging}
          onMouseUp={stopDragging}
          onMouseMove={mouseMove}
          onScroll={onScroll}
        >
          {projectsToShow?.map((project) => (
            archiveMode ? 
            <ArchiveProject project={project} key={project.id + Math.random()}/>
            :
            <Project project={project} key={project.id + Math.random()}/>
          ))} 
        </div>
      </SliderContainer>
    </>
  );
};
export default SideMenu;
