import styled from "styled-components";

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

   @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
    gap: 20px;
  }
`;
const ContactList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  
   @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;
const ContactItem = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const WebLink = styled.a`
  display: flex;
`;

const Contact = () => {

  const isMobile = window.innerWidth <= 768;

  return (
    <ContactContainer>
      <ContactList>

        <ContactItem href="mailto: aitorbigas@gmail.com">
          aitorbigas@gmail.com
        </ContactItem>
        {isMobile ? (<><ContactItem href="https://instagram.com/aitorbigas">
          @aitorbigas
        </ContactItem> <ContactItem href="https://vimeo.com/aitorbigas">
          vimeo.com/aitorbigas
        </ContactItem> </>) : 
        <><ContactItem href="https://vimeo.com/aitorbigas">
          vimeo.com/aitorbigas
        </ContactItem>
        <ContactItem href="https://instagram.com/aitorbigas">
          @aitorbigas
        </ContactItem></>}
      </ContactList>
      <WebLink href="https://progresso-books.com" target="_blank">progresso-books.com</WebLink>
    </ContactContainer>
  );
};

export default Contact;
