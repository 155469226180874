import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const IframeContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 150px;
  opacity: ${(props) => props.show};

  .videoWrapper{
    width: 100%;
    height: 100%;
    position: relative;
  }

  video {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
  }
  
  @media (max-width: 768px) {
    padding-top: 0;
  }
`;
  const Still = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: ${(props) => props.width1} !important;
    height: ${(props) => props.height1}px;
    background-image: url(${(props) => props.src});
    background-size: cover;
    background-position: center;
    display: flex;
    margin: auto;
    z-index: 1000;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
        
    &.hidden{
      display: none;
    }
  `;

  const Play = styled.div`
  top: 50%;
  left: 50%;
    display: flex;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  `;


const Iframe = () => {
  const { project } = useSelector((state) => state.project);
  const video = useRef(null);
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const [isStillHidden, setIsStillHidden] = useState(true);
  const isMobile = window.innerWidth < 768;
  
  useEffect(()=>{
    video.current?.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    })
    video.current.controlsList = "noplaybackrate nodownload";
    setWidth(null);
    video.current?.load();
    if(video){
      // if(isMobile){
      //   video.current.onloadedmetadata = () => {
      //     setHeight("auto");
      //     setWidth("100%");
      //     setIsStillHidden(false);
      //   };

      // }else{        
        video.current.onloadedmetadata = () => {
          setHeight(video.current.clientHeight);
          setWidth(video.current.clientWidth + "px");
          setIsStillHidden(false);
        // };
      }
    }
  }, [isMobile, project])

  const hideStill = () => {
    setIsStillHidden(true);
    video.current.play();
  }
    
  return (
    <IframeContainer className="IFRAME" show={width ? '100' : "0"}>
      <div className="videoWrapper">
        { width && <Still src={`/images/stills/${project.still}`} 
        alt="still" 
        className={isStillHidden ? "still hidden" : "still"}
        onClick={hideStill}
        width1={width} 
        height1={height}><Play>PLAY</Play></Still> }

        <video ref={video} controls disablePictureInPicture controlsList="nodownload">
          <source src={project.url} type="video/mp4" />
        </video>
      </div>
    </IframeContainer>
  );
};

export default Iframe;
