import styled from "styled-components";

const TitleText = styled.h1`
  display: flex;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
`;

const Title = () => {
  return (
    <TitleText>
      AITOR BIGAS <br /> FILM EDITOR & DIRECTOR
    </TitleText>
  );
};

export default Title;
