import { createSlice } from "@reduxjs/toolkit";

const projectlice = createSlice({
  name: "project",
  initialState: {
    showingProject: false,
    project: { title: "", images: [], isVideo: false },
  },
  reducers: {
    setProject: (state, action) => {
      state.project = action.payload;
      state.showingProject = true;
    },
    cleanProject: (state) => {
      state.project = { title: "", images: [], isVideo: false };
      state.showingProject = false;
    },
  },
});

export const {
  setProject: setProjectActionCreator,
  cleanProject: cleanProjectActionCreator,
  setShowingProject: setShowingProjectActionCreator,
} = projectlice.actions;

export default projectlice.reducer;
