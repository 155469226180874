import { createSlice } from "@reduxjs/toolkit";
import { fakeProjects } from "../Data/Data";

const allProjectsSlice = createSlice({
  name: "filters",
  initialState: {
    projects: fakeProjects,
    filter: "all",
  },
  reducers: {
    filterProjects: (state, action) => {
      state.filter = action.payload;
      state.projects = fakeProjects.filter((project) =>
        project.tags.includes(action.payload)
      );
    },
    resetFilters: (state) => {
      state.filter = "all";
      state.projects = fakeProjects;
    }
  }
});

export const { filterProjects: filterProjectsActionCreator, resetFilters: resetFiltersActionCreator } = allProjectsSlice.actions;

export default allProjectsSlice.reducer;
