import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { setProjectActionCreator } from "../../../redux/projectSlice";

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0 15px;
  padding-bottom: 5px;
  padding-top: 8px;
  
  &.active, &:hover{
    background-color: #f3f3f3;
  }
  cursor: pointer;
  & h2,
  p {
    font-size: 10px;
    font-weight: 400;
  }
  @media (max-width: 768px) {
    min-width: 250px;
    max-width: 250px;
    justify-content: flex-start;
    height: 180px;
    &.active{
      background-color: none;
    }
  }
`;

const ProjectImage = styled.div`
  /* width: 100%;
  height: 200px; */
  background: no-repeat center center;
  background-size: ${(props) => props.vertical ? 'auto 100%' : '100% auto'};

  background-image: ${(props) => `url(${props.image})`};
  margin-bottom: ${(props) => props.vertical ? '5px' : '0px'};

  position: relative;
  width: 100%;

  :after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
   @media (max-width: 768px) {
    height: 150px;
  }

`;

const Project = ({ project }) => {
  const dispactch = useDispatch();
  const setProject = () => {
    dispactch(setProjectActionCreator(project));
  };
  const activeProject = useSelector((state) => state.project.project);
  const refImage = useRef(null);
  const imageContainer = useRef(null)

  return (
    <ProjectContainer ref={imageContainer} className={activeProject.id === project.id ? "active" : ""} onClick={setProject}>
      <ProjectImage ref={refImage} vertical={project.vertical} image={project.isVideo ? `/images/stills/${project?.still}` : `/images/${project?.still}`} />
      <h2>{project.title}</h2>
      <p>{project.subtitle}</p>
    </ProjectContainer>
  );
};

export default Project;
