import { configureStore } from "@reduxjs/toolkit";
import projectSliceReducer from "./projectSlice";
import allProjectsSliceReducer from "./allProjectsSlice";
import imageToShowReducer from "./imageToShow";
import archiveModeSliceReducer from "./archiveModeSlice";

const store = configureStore({
  reducer: {
    project: projectSliceReducer,
    allProjects: allProjectsSliceReducer,
    archiveMode: archiveModeSliceReducer,
    imageToShow: imageToShowReducer,
  },

});

export default store;
