export const projectCounterByTag = (projects, tag) => {
  
  let numberOfProjects = projects.filter((project) => project.tags.includes(tag));

  if(tag === "all") {
    numberOfProjects = projects;
  }

  if(numberOfProjects.length !== 0){
    if(numberOfProjects.length.toString().split("").length === 1)
      return `00${numberOfProjects.length}`;
    else if(numberOfProjects.length.toString().split("").length === 2)
      return `0${numberOfProjects.length}`;
    else
      return numberOfProjects;
  }
  else{
    return `000`;
  }
}
