import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { filterProjectsActionCreator, resetFiltersActionCreator } from "../../../redux/allProjectsSlice";
import { toggleArchiveModeActionCreator } from "../../../redux/archiveModeSlice";

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 50px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
const FilterItem = styled.div`
  display: flex;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &.active {
    text-decoration: underline;
  }

`;

const Filters = ({filters}) => {
  const dispatch = useDispatch();
  const activeFilter = useSelector((state) => state.allProjects.filter);
  const archiveModeOn = useSelector((state) => state.archiveMode.archiveMode);

  const setFilter = (filter) => {
    if (archiveModeOn){
      dispatch(toggleArchiveModeActionCreator())
    }
    dispatch(filterProjectsActionCreator(filter));
  };

  const resetFilters = () => {
    if (archiveModeOn){
      dispatch(toggleArchiveModeActionCreator())
    }
    dispatch(resetFiltersActionCreator());
  };

  const archiveMode = () => {
    dispatch(toggleArchiveModeActionCreator())
    dispatch(resetFiltersActionCreator());
  };

  return (
    <FiltersContainer>
      <FilterItem 
        className={activeFilter === "all" ? "active" : ""} 
        onClick={resetFilters}>
          ALL 
      </FilterItem>
      {filters.map((filter)=>{return <FilterItem  className={activeFilter === filter.name ? "active" : ""} onClick={()=>setFilter(filter.name)} key={filter.name}>{filter.name.toUpperCase()}</FilterItem>})}
      <FilterItem 
        className={archiveModeOn ? "active" : ""} 
        onClick={archiveMode}>
          ARCHIVE
        </FilterItem>
    </FiltersContainer>
  );
};

export default Filters;
