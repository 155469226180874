import { createSlice } from "@reduxjs/toolkit";

const imageToShow = createSlice({
    name: "imageToShow",
    initialState: "",
    reducers: {
        setImageToShow: (state, action) => {
            return action.payload;
        },
        resetImageToShow: (state) => {
            return "";
        }
    }
});

export const { setImageToShow: setImageToShowActionCreator, resetImageToShow: resetImageToShowActionCreator } = imageToShow.actions;

export default imageToShow.reducer;