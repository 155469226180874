import { useEffect, useRef, useState } from "react"
import styled from "styled-components"

const VideoAnimationContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  z-index: 10000;

  & .video-animation__video {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
  }
`

const VideoAnimation = () => {
  const videoRef = useRef(null);
  const [showVideo, setShowVideo] = useState(true);
  const [black, setBlack] = useState(true);
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    if(videoRef.current){
      videoRef.current?.addEventListener('loadeddata', function() {
      setBlack(false);
      videoRef.current.play();
    }, false);
      videoRef.current.addEventListener("ended", () => {
        setShowVideo(false);
      })
    }
  }, [videoRef])

  return showVideo && 
  <VideoAnimationContainer>
  { black && <div className="black"></div>}
    <video
      ref={videoRef}
      className="video-animation__video"
      muted
      playsInline
      autoPlay
    >
      <source 
      src={isMobile ?  "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/INTRO_VERTICAL.mp4?alt=media&token=f6750514-1133-4668-8d5b-35c93b483cef" : "https://firebasestorage.googleapis.com/v0/b/aitorbigasweb.appspot.com/o/INTRO_HORIZONTAL.mp4?alt=media&token=cc2bfa31-5746-4e68-a7af-af7beba92b20"} type="video/mp4" />
    </video>
  </VideoAnimationContainer>
}

export default VideoAnimation