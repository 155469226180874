import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components"
import { resetImageToShowActionCreator } from "../../redux/imageToShow";

const ModalImageContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: no-repeat center center;
  background-size: auto 100%;
  background-image: ${(props) => `url(/images/${props.image})`};
  z-index: 100;
  display: none;

  &.active{
    display: flex;
  }

  /* ::before {
  display: ${(props) => props.vertical ? "none" : "block"};
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  z-index: -1;
  background: ${(props) => `url(/images/${props.image}) 0 0 repeat`};
  transform: rotate(30deg); 
  } */
`

const ModalImage = ()=>{
  const [url, setUrl] = useState(null);
  const imageToShow = useSelector((state) => state.imageToShow);
  const dispatch = useDispatch();

  useEffect(() => {
  //  const horizontal = imageToShow.split('horizontal').length > 1;
  //   if(horizontal && isMobile){
  //     setUrl(imageToShow.split('horizontal')[0] + '_horizontal.webp')
  //   }
  //   else{
      setUrl(imageToShow)
    // }
  }, [imageToShow])

  const closeModal = () => {
    dispatch(resetImageToShowActionCreator());
  }

  return <ModalImageContainer 
    onClick={closeModal} 
    className={imageToShow ? "active" : ""} 
    image={url} 
  />
}

export default ModalImage